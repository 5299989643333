<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the theoretical reaction shown below:</p>

      <p class="mb-5 pl-14">
        <chemical-latex content="2A(aq) + B(aq) -> C(aq) + 2D(aq)" />
      </p>

      <p class="mb-3">
        If
        <stemble-latex content="$\Delta\text{G}^\circ_\text{rxn}$" />
        for this process is
        <number-value :value="deltaG" unit="\text{kJ/mol}" />
        determine the equilibrium constant for the reaction at
        <stemble-latex content="$25^\circ\text{C}$" />.
      </p>

      <calculation-input v-model="inputs.Kc" prepend-text="$\text{K:}$" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question422',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kc: null,
      },
    };
  },
  computed: {
    deltaG() {
      return this.taskState.getValueBySymbol('deltaG').content;
    },
  },
};
</script>
